import { Link, StaticQuery, graphql } from "gatsby"
import PropTypes from "prop-types"
// import Context from "../store/context"
//import useDarkMode from '@oceanprotocol/use-dark-mode';

import {useHasMounted} from '../utils/hooks';
import {useDarkMode} from '../utils/darkMode';

import React, { useState } from "react"
import { useLocation } from '@reach/router';

const Header = ({ siteTitle }) => {
  // const {state, dispatch} = useContext(Context)
  //const darkMode = useDarkMode(false, {
    //classNameDark: 'dark',
    //classNameLight: 'light'
  //});

  const location = useLocation();

  const hasMounted = useHasMounted();
	//const [mode, {toggle}] = useDarkMode();
  const [darkMode, {toggle}] = useDarkMode();

  const [isExpanded, toggleExpansion] = useState(false)
  const Nav = (
    <>
      <StaticQuery
        query={graphql`
          query {
            allStrapiNavigation(sort: {order: ASC}) {
              nodes {
                id
                title
                path
                type
                menuAttached
                related {
                  id
                  publishedAt
                }
                items {
                  id
                  title
                  path
                  type
                  menuAttached
                  related {
                    publishedAt
                    id
                  }
                }
              }
            }
          }
        `}
        render={data => (<>
          <Link onClick={() => toggleExpansion(false)} className={`block hover:bg-neutral-200 dark:hover:bg-neutral-800 rounded-full px-4 py-2 mt-4 mr-2 text-neutral-900 hover:text-neutral-700 dark:text-white lg:inline-block lg:mt-0 dark:hover:text-neutral-300 ${location.pathname == "/" ? 'lg:text-white' : ''}`} to="/">Home</Link>
          {data.allStrapiNavigation.nodes.map((edge, key) => {
            switch (edge.type) {
              case "INTERNAL":
                if (edge.menuAttached === true
                  && edge.related !== null
                  && edge.related.publishedAt !== null
                ) {
                  // passed INTERNAL test
                  return (
                    <Link key={edge.id} onClick={() => toggleExpansion(false)} className={`${key === 1 ? 'llspacer' : ''} ${key === 2 ? 'rlspacer' : ''} block hover:bg-neutral-200 dark:hover:bg-neutral-800 rounded-full px-4 py-2 mt-4 mr-2 text-neutral-900 hover:text-neutral-700 dark:text-white lg:inline-block lg:mt-0 dark:hover:text-neutral-300 ${location.pathname == "/" ? 'lg:text-white' : ''}`} to={`${edge.path}`}>{edge.title}</Link>
                  )
                }
                return (<></>)

              case "EXTERNAL":
                if (edge.menuAttached === true) {
                  // passed EXTERNAL test
                  return (
                    <a key={edge.id} onClick={() => toggleExpansion(false)} className={`${key === 1 ? 'llspacer' : ''} ${key === 2 ? 'rlspacer' : ''} block hover:bg-neutral-200 dark:hover:bg-neutral-800 rounded-full px-4 py-2 mt-4 mr-2 text-neutral-900 hover:text-neutral-700 dark:text-white lg:inline-block lg:mt-0 dark:hover:text-neutral-300 ${location.pathname == "/" ? 'lg:text-white' : ''}`} href={`${edge.path}`}>{edge.title}</a>
                  )
                }
                return (<></>)

              case "WRAPPER":
                if (edge.menuAttached === true
                  && edge.items !== null
                ) {
                  // passed WRAPPER test
                  return (
                    <div key={edge.id}
                      className={`${key === 1 ? 'llspacer' : ''} ${key === 2 ? 'rlspacer' : ''} static lg:relative group block lg:inline-block mt-4 lg:mt-0 lg:pb-4 mx-2 pb-2`}>
                      <div className={`block text-sm font-semibold uppercase lg:normal-case cursor-pointer text-neutral-900 hover:text-neutral-700 group-hover:bg-neutral-200 dark:group-hover:bg-neutral-800 dark:group-hover:text-neutral-300 dark:text-white lg:rounded-full mx-4 lg:mx-0 lg:px-4  py-2 border-neutral-300 dark:border-neutral-700 border-b-2 lg:border-0 ${location.pathname == "/" ? 'lg:text-white' : ''}`}>{edge.title}</div>
                      <div className="lg:absolute lg:hidden group-hover:block right-0 lg:mt-2 z-50 block lg:w-52 lg:py-2 lg:bg-neutral-300 lg:dark:bg-neutral-900 dark:text-neutral-300 lg:rounded-xl lg:shadow-xl">
                        {edge.items.map(item => {
                          if (item.type === 'EXTERNAL' && item.menuAttached === true) {
                            // passed EXTERNAL test
                            return (
                              <a key={item.id} onClick={() => toggleExpansion(false)} className="block mt-4 first:mt-0 lg:first:mt-2 ml-4 lg:ml-0 text-neutral-900 py-2 lg:py-0 px-4 hover:text-neutral-500 dark:text-neutral-300 lg:my-3 dark:hover:text-white" href={`${item.path}`}>{item.title}</a>
                            )
                          } else if (item.menuAttached === true
                            && item.related !== null
                            && item.related.publishedAt !== null
                          ) {
                            // passed INTERNAL test
                            return (
                              <Link key={item.id} onClick={() => toggleExpansion(false)} className="block mt-4 first:mt-0 lg:first:mt-2 ml-4 lg:ml-0 text-neutral-900 py-2 lg:py-0 px-4 hover:text-neutral-500 dark:text-neutral-300 lg:my-3 dark:hover:text-white" to={`${item.path}`}>{item.title}</Link>
                            )
                          } else {
                            return (<></>)
                          }
                        })}
                      </div>
                    </div>)
                }
                return (<></>)

              default:
                return (<></>)
            }
          })}
        </>)}
      />
    </>
  )

  return (
    <nav className="relative z-20">
      <div className="bg-neutral-800 py-3 text-neutral-400">
        <div className="m-auto max-w-7xl w-full px-4 flex justify-between">
          <a className="hover:text-white" target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/fresnofiretx/">FIND US ON FACEBOOK</a>
          <button
            //onClick={darkMode.toggle} className="transition duration-200 ease-out hover:scale-125 top-[1.65rem] mr-16 lg:mr-0  self-start inline-block text-neutral-300 hover:text-white" >
            //<use xlinkHref={`${darkMode.value ? "#icon-light-mode" : "#icon-dark-mode"}`}></use>
             //onClick={toggle} className="transition duration-200 ease-out hover:scale-125 top-[1.65rem] mr-16 lg:mr-0  self-start inline-block text-neutral-300 hover:text-white" >
            onClick={toggle} className="transition duration-200 ease-out hover:scale-125 top-[1.65rem] mr-16 lg:mr-0  self-start inline-block text-neutral-300 hover:text-white" >
            <svg fill="currentColor" className="w-6 h-6">
              <use xlinkHref={`${darkMode ? "#icon-light-mode" : "#icon-dark-mode"}`}></use>
              {/* <use xlinkHref={`${state.isDark ? "#icon-light-mode" : "#icon-dark-mode"}`}></use> */}
            </svg>
          </button>
        </div>
      </div>


      <div className="container max-w-7xl mx-auto justify-between px-6 pt-6 mb-6 pr-20 lg:pr-4 relative">
        <div className="absolute -top-6 mx-auto text-red-700 dark:text-white left-1/2 -ml-20">
          <span className="block lg:text-xl font-semibold tracking-tight">
            {/* {darkMode.value ? (<img className=""src={logoLight} alt="logo" />) :(<img src={logoDark} alt="logo" />) } */}
            <svg fill="currentColor" className="h-48 w-40">
              <use xlinkHref={`#logo`}></use>
            </svg>

          </span>
        </div>

        <div className={`${isExpanded ? `block` : `hidden`} z-40 w-screen h-screen lg:h-fit lg:w-full fixed top-0 left-0 lg:static flex-grow lg:flex lg:items-center lg:w-auto bg-neutral-100 dark:bg-neutral-800 dark:lg:bg-transparent lg:bg-transparent`} >
          <div className="lg:flex lg:font-semibold justify-between items-start mb-20 h-screen py-14 lg:py-0 lg:h-fit overflow-y-scroll lg:overflow-visible text-xl lg:text-sm lg:flex-grow lg:text-right">
            {Nav}
          </div>
        </div>
      </div>

      {/* MOBILE MENU BUTTON */}
      <div className="fixed right-4 top-0 z-50 lg:hidden">
        <button
          onClick={() => toggleExpansion(!isExpanded)}
          className="px-3 pt-8 pb-6 text-neutral-300 hover:text-neutral-100 dark:text-neutral-900 dark:hover:text-neutral-700 dark:text-white bg-neutral-900 dark:bg-neutral-100  rounded-b-full dark:hover:text-neutral-300"
        >
          <span className={`${isExpanded ? `translate-y-2 rotate-45` : ` `} transition-all block w-6 h-1 border-neutral-100 dark:border-neutral-900 border-b-2`}></span>
          <span className={`${isExpanded ? `!w-0 translate-x-3` : ` `} transition-all block mt-1 w-6 h-1 border-neutral-100 dark:border-neutral-900 border-b-2`}></span>
          <span className={`${isExpanded ? `-translate-y-2 -rotate-45` : ` `} transition-all block mt-1 w-6 h-1 border-neutral-100 dark:border-neutral-900 border-b-2`}></span>
        </button>
      </div>
    </nav>
  )
}
// onClick={() => dispatch({ type: "TOGGLE_DARK_MODE" })} className="absolute transition duration-200 ease-out hover:scale-125 top-[1.65rem] lg:top-7  right-20 lg:right-6 inline-block text-neutral-900 hover:text-neutral-700 dark:text-white dark:hover:text-neutral-300 " >

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
